<template>
  <v-app-bar
    app
    ref="navbar"
    class="navbar-site white"
    clipped-left
    hide-on-scroll
    scroll-target
    :extension-height="$vuetify.breakpoint.smAndDown ? 112 : 149"
    :height="
      applicationConfig && !alreadyShowedNewsBanner
        ? $vuetify.breakpoint.smAndDown
          ? '60'
          : '35'
        : '0'
    "
  >
    <!-- <div class="d-flex justify-center w-100 h-100"> -->
    <template
      v-if="
        (applicationConfig && !alreadyShowedNewsBanner) ||
          (orderToPay && orderToPay.length > 0 && !hidePendingOrders)
      "
    >
      <template v-if="applicationConfig && !alreadyShowedNewsBanner">
        <category-block
          v-if="applicationConfig && !alreadyShowedNewsBanner"
          :target="applicationConfig"
          position="header"
          class="category-block category-block-header w-100 h-100"
          :container="false"
        />
        <v-btn
          class="close-header-banner-btn close-news"
          icon
          color="default"
          @click="closeBannerNews()"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
      <div
        v-if="orderToPay && orderToPay.length > 0 && !hidePendingOrders"
        class="uncompleted-orders white--text primary d-flex align-center justify-center w-100 h-100"
      >
        <span class="px-1">
          {{ $t("navbar.uncompletedOrders") }}&nbsp;
          <router-link
            class="white--text text-decoration-none"
            v-for="(orderId, index) in orderToPay"
            :key="orderId"
            :to="{ name: 'Order', params: { orderId: orderId } }"
          >
            {{ orderId
            }}<template v-if="index < orderToPay.length - 1">,&nbsp;</template>
          </router-link>
        </span>
        <v-btn
          class="close-header-banner-btn close-uncompleted-orders"
          icon
          color="default"
          @click="hidePendingOrders = true"
          :aria-label="$t('common.close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </div>
    </template>
    <!-- </div> -->
    <template v-slot:extension>
      <!-- FIXED CONTENT -->
      <!-- top menu -->
      <div class="navigation-selector-container d-none d-md-flex">
        <div class="container py-0">
          <LinksMenu v-if="$vuetify.breakpoint.mdAndUp" :items="primaryLinks" />
        </div>
      </div>
      <div :class="$vuetify.breakpoint.smAndDown ? '' : 'container py-0'">
        <!-- navbar -->
        <div class="navbar d-flex align-center flex-wrap flex-md-nowrap">
          <!-- navigazione (solo smAndDown) -->
          <div class="d-flex justify-start align-center p-sm-auto">
            <v-btn
              v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
              :to="{ path: $routerHistory.previous().path }"
              elevation="0"
              aria-label="Torna Indietro"
            >
              <v-icon large elevation="0">$chevronLeft</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              :height="$vuetify.breakpoint.mdAndUp ? '70' : '50'"
              :width="$vuetify.breakpoint.mdAndUp ? '70' : '50'"
              elevation="0"
              tile
              aria-label="Prodotti"
              class="menu_btn ml-3 ml-md-0"
              :small="!$vuetify.breakpoint.mdAndUp"
              @click.prevent.stop="
                $store.commit('custom/updateMenuPositionModel', 0);
                drawerLeft = !drawerLeft;
                w;
              "
            >
              <v-icon large class="btn_icon">$menu</v-icon>
              <span class="btn_label d-none d-md-inline">{{
                $t("categories.buttonLabel")
              }}</span>
            </v-btn>
          </div>

          <!-- logo col -->
          <div
            class="order-1 flex-grow-1 align-center align-md-left d-flex justify-start"
          >
            <router-link
              :to="{ name: 'Home' }"
              class="logo-link"
              aria-label="Torna alla homepage"
              title="Clicca per andare alla homepage"
            >
              <!-- logo -->
              <img
                id="logo-coop-shop"
                class="logo"
                :src="serviceImg"
                alt="Logo Coop Shop"
              />
            </router-link>
          </div>
          <!-- search col -->
          <div
            class="order-4 order-md-2 d-flex px-md-5  mt-1 mt-md-0 px-3 flex-grow-1 w-100"
          >
            <SearchInput class="pr-auto pr-lg-auto" />
          </div>
          <!-- buttons col -->
          <div
            class="nav-buttons order-2 order-md-3 d-flex justify-end align-center pr-0 pr-md-3"
          >
            <template v-if="!isAuthenticated">
              <v-btn
                @click.stop="handleLogin()"
                medium
                icon
                tile
                class="d-inline"
                height="50"
                :aria-label="$t('login.title')"
              >
                <v-icon color="default">$profile</v-icon>
              </v-btn>
              <v-btn
                height="50"
                icon
                tile
                @click.stop="handleLogin()"
                class="mr-2"
                :aria-label="$t('bottom.cart')"
              >
                <v-icon color="default">$cart</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <div class="delivery-service-selector d-flex align-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openAddressSelector"
                      class="d-none d-md-inline service-button"
                      height="50"
                      alt="Selezione servizio"
                      aria-label="Selezione servizio"
                    >
                      <v-icon x-large class="service-icon">
                        {{
                          "$" +
                            $t(
                              `navbar.service.${getDeliveryServiceId}.fontIcon`
                            )
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="delivery-service-tooltip-content">
                    <strong
                      >{{ shippingAddress }}<br />
                      {{ timeslot }}</strong
                    >
                  </span>
                </v-tooltip>
                <div class="nav-selectors d-none d-lg-inline" v-if="!isFast">
                  <v-btn
                    x-small
                    text
                    class="d-flex justify-space-between"
                    @click="openAddressSelector"
                  >
                    <span class="info-text font-weight-bold">{{
                      shippingAddress
                    }}</span
                    ><v-icon
                      v-if="editableAddress"
                      color="default"
                      x-small
                      class="ml-1"
                      >$edit</v-icon
                    >
                  </v-btn>
                  <v-btn
                    x-small
                    text
                    class="d-flex justify-space-between"
                    @click="openTimeslotSelector"
                  >
                    <span class="info-text font-weight-regular text-lowercase">
                      {{ timeslot }} </span
                    ><v-icon
                      v-if="editableTimeslot"
                      color="default"
                      x-small
                      class="ml-1"
                      >$edit</v-icon
                    >
                  </v-btn>
                </div>
                <div class="d-lg-flex d-none flex-column " v-if="isFast">
                  <div class="nav-selectors d-none d-lg-inline">
                    <v-btn
                      x-small
                      text
                      class="d-flex justify-space-between"
                      @click="openAddressSelector"
                    >
                      <span class="info-text font-weight-bold">{{
                        shippingAddress
                      }}</span
                      ><v-icon
                        v-if="editableAddress"
                        color="default"
                        x-small
                        class="ml-1"
                        >$edit</v-icon
                      >
                    </v-btn>
                    <v-btn
                      x-small
                      text
                      class="d-flex justify-space-between"
                      @click="openTimeslotSelector"
                    >
                      <span
                        class="info-text font-weight-regular text-lowercase"
                      >
                        {{ isFast ? timeslot.split("-")[0] : timeslot }} </span
                      ><v-icon
                        v-if="editableTimeslot"
                        color="default"
                        x-small
                        class="ml-1"
                        >$edit</v-icon
                      >
                    </v-btn>
                  </div>
                  <div class="d-flex">
                    <span class="expiry-date rounded-sm white--text">
                      <p class="px-4 mb-0 text-uppercase">
                        <strong style="font-size: 12px"
                          >Scade tra {{ minutesToFastTimeslot }} min</strong
                        >
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <gift-menu-button />

              <div class="d-flex flex-row align-center mr-2 text-caption">
                <v-btn
                  :aria-label="$t('profile.goToProfileBtn')"
                  @click="openDashboard"
                  medium
                  depressed
                  tile
                  :icon="!$vuetify.breakpoint.lgAndUp"
                  :color="
                    $vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'
                  "
                  class="profile-btn"
                  height="50"
                >
                  <v-icon color="secondary">$profile</v-icon>
                  <span class="d-none d-lg-inline-block align-left welcome">
                    <div class="points" v-if="cart.user.fidelityCard">
                      <img
                        src="/img_layout/socio-coop.svg"
                        alt="Tessera socio coop"
                      />
                      <div class="small">
                        {{ cart.userPoints["98000002"].pointsAvailable }}
                        punti
                      </div>
                    </div>
                    <div v-else>
                      Ciao <br />
                      {{ cart.user.firstName }}
                    </div>
                  </span>
                </v-btn>
                <transition
                  name="custom-classes"
                  enter-active-class="animate__animated animate__swing"
                >
                  <v-badge
                    :content="totalItems"
                    :value="totalItems > 0"
                    :key="totalItemQuantity"
                    color="primary"
                    overlap
                    :offset-x="$vuetify.breakpoint.mdAndDown ? 22 : 91"
                    offset-y="25"
                    class="d-flex flex-row align-center font-weight-bold cart-badge"
                  >
                    <v-btn
                      aria-label="Apri carrello"
                      medium
                      :icon="!$vuetify.breakpoint.lgAndUp"
                      depressed
                      tile
                      :color="
                        $vuetify.breakpoint.lgAndUp ? 'transparent' : 'default'
                      "
                      @click.stop="openCart()"
                      class="cart-btn"
                      height="50"
                      :width="$vuetify.breakpoint.mdAndDown ? 50 : 110"
                    >
                      <v-icon color="default">$cart</v-icon>
                      <div
                        class="d-none d-lg-inline-block align-left cart-total"
                        v-html="
                          `<b class='default--text ml-1'> ${$n(
                            cart.totalPrice,
                            'currency'
                          )} </b>`
                        "
                      ></div>
                    </v-btn>
                  </v-badge>
                </transition>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="primary d-none d-md-flex header-banner">
        <div class="container py-0">
          <v-chip-group center-active show-arrows v-if="navbarItems">
            <v-chip
              v-for="(proposal, index) in navbarItems.proposals"
              :key="index"
              small
              label
              color="white"
              :to="{
                name: 'Category',
                params: { pathMatch: proposal.slug }
              }"
            >
              <v-avatar size="20" left>
                <img
                  width="20"
                  height="20"
                  :src="proposal.img"
                  :alt="`categoria-${proposal.descr}`"
                  :title="`L'icona-${proposal.descr}`"
                />
              </v-avatar>
              {{ proposal.descr }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
      <v-progress-linear
        aria-label="Caricamento in corso"
        :active="showOverlay"
        :indeterminate="showOverlay"
        absolute
        bottom
        :color="$vuetify.breakpoint.mdAndUp ? 'white' : 'primary'"
      ></v-progress-linear>
    </template>
  </v-app-bar>
</template>

<script>
import SearchInput from "@/components/navigation/SearchInput.vue";
//import UserMenu from "@/components/navigation/UserMenu.vue";
// import Login from "@/components/Login.vue";
import LinksMenu from "@/components/navigation/LinksMenu.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import clickHandler from "~/mixins/clickHandler";

// import get from "lodash/get";
import { mapActions, mapState, mapGetters } from "vuex";
import GiftMenuButton from "../gift/GiftMenuButton.vue";

export default {
  name: "Navbar",
  mixins: [login, clickHandler, cartInfo],
  components: {
    SearchInput,
    LinksMenu,
    GiftMenuButton
  },
  props: {
    primaryLinks: { type: Array, required: false },
    navbarItems: { type: Object, default: () => {} },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      bannerTopKey: 0,
      hidePendingOrders: false,
      minutesToFastTimeslot: 0
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updateOrderToPay: "custom/updateOrderToPay"
    }),
    startFastTimeslotCountdown() {
      let _this = this;
      const interval = setInterval(() => {
        _this.minutesToFastTimeslot = this.$dayjs().diff(
          _this.cart.timeslot.date + " " + _this.cart.timeslot.beginTime,
          "minute",
          true
        );

        _this.minutesToFastTimeslot = Math.abs(_this.minutesToFastTimeslot);

        if (_this.minutesToFastTimeslot <= 0) {
          clearInterval(interval);
          return;
        }

        _this.minutesToFastTimeslot = Math.floor(_this.minutesToFastTimeslot);

        console.log(_this.minutesToFastTimeslot);
      }, 60000);
    },
    closeBannerNews() {
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = {};
          bannersTopShowingDate[headerNews.categoryBlockId] = new Date(
            Date.now() + 3600 * 1000 * 24
          ).toISOString();
          window.sessionStorage["bannersTopShowingDate"] = JSON.stringify(
            bannersTopShowingDate
          );
        }
      }
      this.bannerTopKey++;
    },
    closeLogin() {
      this.$refs.loginMenuRef.isActive = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push({ name: "Cart" });
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    openDashboard() {
      if (this.isAuthenticated) {
        this.$router.push("/profile/dashboard");
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      cart: ({ cart }) => cart.cart,
      orderToPay: state => state.custom.orderToPay
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    totalItemQuantity() {
      return this.cart.cartItems?.reduce((acc, item) => {
        return acc + item.quantity + item.weight;
      }, 0);
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    alreadyShowedNewsBanner() {
      this.bannerTopKey;
      if (this.applicationConfig.categoryBlocks) {
        let headerNews = this.applicationConfig.categoryBlocks.find(
          block => block.templateBlock.codInt == "header"
        );
        if (headerNews) {
          let bannersTopShowingDate = window.sessionStorage[
            "bannersTopShowingDate"
          ]
            ? JSON.parse(window.sessionStorage["bannersTopShowingDate"])
            : {};
          let catBlockId = headerNews.categoryBlockId;

          let nextOpeningDate = bannersTopShowingDate[catBlockId];
          let today = new Date();
          if (
            Object.prototype.hasOwnProperty.call(
              bannersTopShowingDate,
              catBlockId
            ) &&
            Date.parse(nextOpeningDate) > Date.parse(today)
          ) {
            // there is banner but close already clicked
            return true;
          }

          // show banner
          return false;
        } else {
          // there is no proposal -> do not show news banner
          return true;
        }
      }
      // there is no banner to show
      return true;
    },
    isFast() {
      let fastIds = [13, 14, 15, 16, 17, 18];
      return fastIds.includes(this.cart.shippingAddress.deliveryServiceId);
    }
  },
  mounted() {
    if (this.isFast) this.startFastTimeslotCountdown();
  },
  watch: {
    isAuthenticated(val, oldVal) {
      if (val != oldVal) {
        if (val == true) {
          this.updateOrderToPay();
        } else {
          this.updateOrderToPay([]);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.expiry-date {
  background-color: #d69333;
}
.primary-links {
  height: 36px;
}
@media (max-width: 365px) {
  .navbar-site {
    .profile-btn {
      display: none;
    }
  }
}

.uncompleted-orders {
  z-index: 14;
  position: absolute;
  font-weight: 600;
}
.shake-enter-active {
  animation: shakeCart 0.4s ease-in-out forwards;
}
@keyframes shakeCart {
  25% {
    transform: translateX(6px);
    opacity: 0.8;
  }
  50% {
    transform: translateX(-4px);
    opacity: 0.8;
  }
  75% {
    transform: translateX(2px);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 0.5;
  }
}
.navbar {
  background-color: $white;
  height: 80px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 112px;
    box-shadow: 0px 2px 13pt #0000004d;
  }
  .menu_btn {
    ::v-deep .v-btn__content {
      flex-direction: column;
      .btn_label {
        font-size: 10px;
      }
    }
  }
}
.welcome {
  text-align: left;
  font-size: 12px;
  line-height: 1;
  color: var(--v-secondary-base);
  text-transform: capitalize;
}

.logo-link {
  .logo {
    width: 200px;
    height: 60px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 133px;
      height: 40px;
    }
  }
}
.navigation-selector-container {
  font-size: 13px;
  font-weight: 600;
  position: relative;
  background-color: var(--v-grey-base);
}

::v-deep .v-toolbar__extension {
  display: block;
  padding: 0px;
  transition: height 0.2s ease;
  -webkit-transition: height 0.2s ease;
}
.header-banner {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    ::v-deep .v-slide-group__content {
      justify-content: center !important;
    }
  }
  .v-chip {
    color: $primary;
    font: normal normal 600 13px/28px $body-font-family;
    .v-avatar {
      height: 20px !important;
      width: 20px !important;
      min-width: 20px !important;
    }
  }
  ::v-deep .v-slide-group__content {
    padding: 1px 0 !important;
  }
}
.nav-selectors {
  .info-text {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.nav-buttons {
  .v-btn {
    padding: 0px 4px;
  }
  .service-icon {
    vertical-align: unset;
  }
  .points {
    img {
      width: 28px;
    }
  }
}
</style>
